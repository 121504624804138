import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Head from '../components/head'

const AboutPage = () => {
    return (
        <Layout>
            <Head title="About"/>
            <h1>Luke Deer</h1>
            <h2>Luke Deer is a freelance researcher, writer and speaker on financial technology, with a focus on developments in China and Australia.</h2>
            <p>Luke contributes to Executive Education on these topics at the The University of Sydney Business School, where he also teaches in the Finance Discipline on international banking and bank supervision.</p>
            <p>Luke's recent projects include an Institute for New Economic Thinking (INET) grant-funded research project with Mike Beggs which explores a ‘Money View’ analysis of  financial innovation and central banking in the recent history of China’s financial system. The results have been published as a book by Palgrave Pivot on Remaking Monetary Policy in China - Markets and Controls, 1998–2008.</p>
            <p>Previously, Luke was the research director for the first Asia-Pacific China Alternative Finance Benchmarking Report published in March 2016. This report was led by the Cambridge Centre for Alternative Finance, with the Tsinghua Graduate School at Shenzhen and the University of Sydney Business School and its Finance Discipline, in partnership with KPMG Australia and its regional offices and supported by ACCA (The Association of Chartered Certified Accountants).</p>
            <p><Link to="/contact">Get in touch if you are interested in working together, exchanging or talking more.</Link></p>
            <h2>Luke’s publications include:</h2>
            <p>Deer, Luke. (2022), <cite><a href="https://media.meeco.me/public-assets/white_papers/Meeco_Carbon_Market_Paper-COP27-2022-11.pdf" target="_blank" rel="noopener noreferrer">Carbon Markets, Tokenisation and the Enterprise Data Challenge</a>. (PDF) Report. COP27 Special Edition published by Meeco in conjunction with the HBAR Foundation.</cite></p>
            <p>Grant, Andrew, and Luke Deer. (2019), <cite><a href="https://doi.org/10.1177/0312896219883678" target="_blank" rel="noopener noreferrer">'Consumer marketplace lending in Australia: Credit scores and loan funding success'</a></cite>, Australian Journal of Management.</p>
            <p>Beggs, Michael, and Luke Deer. (2019), <cite><a href="https://www.palgrave.com/gp/book/9789811397257" target="_blank" rel="noopener noreferrer">Remaking Monetary Policy in China - Markets and Controls, 1998–2008</a></cite>. Palgrave Pivot. Palgrave.</p>
            <p>Deer, Luke. (2019). <cite><a href="https://www.australiachinarelations.org/content/fintech-payments-innovation-and-cross-border-payments-integration-between-australia-and" target="_blank" rel="noopener noreferrer">Fintech Payments Innovation and Cross-Border Payments Integration between Australia and China.</a></cite> ACRI (UTS) Australia-China Relations Institute Report.</p>
            <p>Deer, Luke. (2018), <cite><a href="https://asia.nikkei.com/Opinion/China-s-plan-to-end-the-anonymity-of-cash" target="_blank" rel="noopener noreferrer">'China's plan to end the anonymity of cash'</a></cite>, Nikkei Asian Review, November 14, 2018.</p>
            <p>Mi, Jackson J., Tianxiao Hu, and Luke Deer. (2018), <cite><a href="https://doi.org/10.1007/s40745-017-0134-z" target="_blank" rel="noopener noreferrer">'User Data Can Tell Defaulters in P2P Lending.'</a></cite> Annals of Data Science. 5 (1): 59–67</p>
            <p>Garvey, K. .... Deer, L. et.al. (2017), <cite><a href="https://www.jbs.cam.ac.uk/fileadmin/user_upload/research/centres/alternative-finance/downloads/2017-09-cultivating-growth.pdf" target="_blank" rel="noopener noreferrer">Cultivating Growth, The 2nd Asia-Pacific Alternative Finance Benchmarking Report</a></cite>, (PDF), Cambridge Centre for Alternative Finance, Tsinghua Graduate School of Shenzhen, Australian Centre for Financial Studies Monash University, in partnership with KPMG, supported by CME Group Foundation and HNA Capital.</p>
            <p>Deer, Luke. and Sandra Peter (2017), <cite><a href="http://sbi.sydney.edu.au/jack-ma-unicorns/" target="_blank" rel="noopener noreferrer">‘Jack Ma and the unicorns’</a></cite>, Sydney Business Insights, originally published in The Australian Financial Review BOSS Magazine, on June 5, 2017.</p>
            <p>Zhang, B., Deer, L., Wardrop, R. et.al. (2016), <cite><a href="http://sydney.edu.au/business/__data/assets/pdf_file/0005/262166/Harnessing-Potential-Report.pdf" target="_blank" rel="noopener noreferrer">Harnessing Potential: The 2015 Asia-Pacific Alternative Finance Benchmarking Report,</a></cite> Cambridge Centre for Alternative Finance, Tsinghua Graduate School of Shenzhen, The University of Sydney Business School, in partnership with KPMG, supported by ACCA.</p>
            <p>Deer, Luke, Mi, Jackson, and Yu Yuxin. (2015), <cite><a href="http://www.accaglobal.com/content/dam/ACCA_Global/Technical/manage/ea-china-p2p-lending.pdf" target="_blank" rel="noopener noreferrer">Peer-to-Peer Lending in China: An Overview and Survey Case Study</a></cite>, (PDF) ACCA Research Report, ACCA (The Association of Chartered Certified Accountants) Global, UK: London. December.</p>
            <p>Deer, Luke. (2014), <cite><a href="https://archive.thechinastory.org/2014/09/a-springtime-for-microfinance-in-china/" target="_blank" rel="noopener noreferrer">‘A Springtime for Microfinance in China?’</a></cite>, The China Story, Australian Centre for China in the World, The Australian National University, September 17, 2014.</p>
            <p>Deer, Luke. and Ligang Song. (2013), <cite><a href="http://onlinelibrary.wiley.com/doi/10.1111/j.1749-124X.2013.12006.x/abstract" target="_blank" rel="noopener noreferrer">International Financial Adjustment for China: A Financial Valuation Approach</a></cite>. China and World Economy, 21: 1–25.</p>
            <p>Deer, Luke and Ligang Song. (2012), <cite><a href="http://onlinelibrary.wiley.com/doi/10.1111/j.1749-124X.2012.01270.x/abstract" target="_blank" rel="noopener noreferrer">China's Approach to Rebalancing: A Conceptual and Policy Framework</a></cite>. China and World Economy, 20: 1–26.</p>
            <p>Deer, Luke and Ligang Song. (2012), <cite><a href="http://www.eastasiaforum.org/2012/04/24/a-structural-approach-to-china-s-rebalancing/" target="_blank" rel="noopener noreferrer">‘A structural approach to China’s rebalancing’</a></cite>, East Asia Forum, April 24, 2012.</p>
            <p><Link to="/contact">Get in touch.</Link></p>
        </Layout>
    )
}

export default AboutPage